// правый блок профиля
.profile-container {
  display: flex;
  flex-direction: column;
}

.profile {
  z-index: 3;
  font-size: 12px;
  min-width: 200px;
  width: 220px;

  .user-block {
    &__wrapper {
      height: 68px;
      min-height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid transparent;
    }
  }

  &__user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 34px;

  }

  &__client-title {
    font-size: 8px;
    line-height: 10px;
    white-space: nowrap;
    padding-left: 51px;
    padding-right: 34px;
  }

  &__ico {
    margin-right: 7px;
  }


  &__user-name {
    white-space: nowrap;
  }

  ul {
    display: none;
    // TODO вынести в общие стили
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      color: #2F80ED;
      cursor: pointer;

      &:hover {
        background-color: #F1F1F1;
      }

      a {
        display: flex;
        padding: 9px 34px 9px 24px;
        color: #373947;
      }

      &:first-child {
      }

      &:last-child {
        padding: 9px 34px 9px 24px;
        //background-color: transparent;
      }
    }
  }

  &.active {
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    top: 105px;

    .user-block {
      &__wrapper {
        border-bottom: 1px solid #DBDBDB;
      }
    }

    ul {
      display: block;
    }
  }
}

.avatar {
  &__black {
    width: 18px !important;
    height: 18px !important;
    background: url("./../../images/header_profile_ico.svg");
  }
}

@media (max-width: 820px) {
  .profile {
    display: none;
  }
}

@media (max-width: 479px) {
  .profile {
    display: none;
  }
}

