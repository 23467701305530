.tags {
  padding-top: 50px;
  &__wrapper {

    min-width: 150px;
  }
}

.menu {
  padding-top: 50px;
  &__wrapper {

    min-width: 150px;
  }
}