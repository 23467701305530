.replay {
  &__form {
    position: relative;
  }

  &__annotation {
    font-size: 10px;
    color: #6c757d;
    position: relative;
    top: -45px;
    margin-left:5px;
  }

  &__textarea_wrapper {
    display: flex;
    height: 80px;
    padding-bottom: 5px;

    textarea {
      font-size: 14px !important;
      width: 100% !important;
      color: #2B323A !important;
      height: 100%;
    }
  }

  &__right_side {
    width: 80px;
  }

  &__btn_wrapper {
    bottom: -5px !important;

  }
}
