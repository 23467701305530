.lent {

  &__wrapper {
    padding: 0;

    .scroll-container {
      height: 405px;
      overflow-y:scroll;
    }

    ul {
      padding-right: 0;

      li {
        div:not(.review) {
          display: flex;

          .date {
            width: auto;
          }

          .text {
            white-space: normal;
          }
        }

        .review {
          &__header {
            line-height: 1rem;

            .name {
              padding-left: 40px;
            }
          }
        }

        &:last-child{
          padding-bottom: 0!important;
        }
      }

    }

    .p-scrollpanel {
      font-size: 12px !important;

      .p-scrollpanel-content {

        div {
          display: flex;
          justify-content: space-between;

          .date {
            width: auto;
          }

          .text {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {

}
