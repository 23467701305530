.page {
  &__404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .centered-block {
      text-align: center;
      position: relative;
      p {
        margin:7px;
      }
      h1 {
        text-align: center;
        font-size: 124px;
      }

      h2 {
        position: relative;
        width: 500px;
        margin: 0 auto;
        font-size: 28px;


      }
    }
  }
}