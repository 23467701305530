@import "src/custom/variables";


.hidden-phone {
  &__wrapper{
    cursor: pointer;
    text-decoration: underline;
  }
}
.page {
  &__application-detail {
    display: flex;
    flex-grow: 1;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: $border-radius;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }


    .application-detail {
      max-width: 1690px;
      width: 1690px;
      min-height: 32px;
      margin: 0 auto 80px;
      padding: 40px 20px 20px 20px;
      display: flex;
      justify-content: space-between;

      &__cart {
        width: 100%;
        margin-right: 10px;
        padding-bottom: 10px;
      }

      .cart {
        &__header {
          display: flex;
          .form-btn {
            &__wrapper {
              display: block;
              position: relative;
              margin: 0 10px 0 0;
              top: -3px;

              .back-btn {
                padding: 0.4rem 0.4rem;
              }
            }
          }
        }

        &__wrapper {
          padding-right: 40px;


          .service {
            padding: 15px 0 20px 0;
            font-family: 'Stolzl', Arial, Helvetica, sans-serif;
            color: #373947;
          }

          .descr {
            padding: 15px 0 25px 0;

            .row {
              display: flex;
              flex-direction: row;
              padding: 5px 0;

              .col-title {
                font-weight: bold;
                width: 150px;
                color: #6C8DA3;
              }

              .col-text {
                width: auto;
              }
            }

          }

          .field-box {
            display: flex;
            justify-content: flex-start;

            flex-wrap: wrap;

            .field {
              margin-bottom: 5px;
              margin-right: 30px;
              width: 280px;

              &__label {
                font-size: 12px;
                line-height: 12px;
                color: #6C8DA3;
                padding-bottom: 2px;
                display: inline-block;
              }

              &__text {
                width: 100%;
                height: 42px;
                //min-width: 20px;
                line-height: 2.5rem;
                border: 0.5px solid #2B323A;
                box-sizing: border-box;
                border-radius: $border-radius;
                background-color: #FAFAFA;
                padding: 0 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

              }
            }
          }

          h1 {
            span {
              color: #6C8DA3;
              font-weight: 450;
              display: block;
            }
          }
        }
      }

      .form-btn {

        &__wrapper {
          margin-top: 20px;
          margin-left: 40px;
          display: flex;
          justify-content: flex-start;

          .p-button {
            padding: 0.4rem 1.5rem
          }
        }
      }

      &__lent {
        overflow: hidden;
        width: 100%;
        border-left: 1px solid #DBDBDB;
        padding-bottom: 30px;
        .slider {
          margin-left: 40px;
          margin-bottom: 25px;

          display: flex;
          width: auto;

          &__wrapper {

          }

        }

        .lent {

          &__wrapper {
            padding-left: 40px;


            ul {
              list-style: none;
              margin: 0;
              padding: 0 10px 0 0;

              li {
                padding: 8px 0;

                div {
                  display: flex;
                }

                .date {
                  color: #6C8DA3;
                  width: 130px;
                  min-width: 130px;
                }

                .text {
                  color: #2B323A;
                  font-weight: bold;
                  display: flex;

                  .nps {
                    margin-left: 10px;

                    img {
                      margin-top: 5px;
                    }
                  }
                }
              }
            }

            .review {
              display: block;
              border: 1px solid #DBDBDB;
              box-sizing: border-box;
              border-radius: 5px;
              margin-top: 20px;

              &__header {
                display: flex;
                justify-content: space-between;

                .name {
                  padding: 20px 0 20px 60px;
                  margin-left: 20px;
                  margin-right: 10px;
                  font-weight: bold;
                  background: url('./../../../../images/review_icon1.svg') left 50% no-repeat;
                }
                .briefcase {
                  background: url('./../../../../images/review_icon2.svg') left 50% no-repeat;
                }

                .rating {
                  padding: 20px 20px 20px 0;
                  min-width: 85px;
                }
              }

              &__body {
                padding: 0 20px 20px;
              }

              &__comment {
                padding-top: 20px;
              }

            }

            .scroll-panel {
              width: 100%;
              height: 405px;

              .p-scrollpanel-bar {
                background: #6C8DA3;
                border-radius: 6px;
                width: 3px;
              }
            }

          }
        }
      }

      .message-form {

        &__wrapper {
          margin-top: 25px;

          .replay {
            &__form {
              height: 80px;
            }

            &__footer {

            }
            &__right_side{
              width:0;
            }
            &__btn_wrapper {
              bottom: 42px;
              right:-4px;
            }
          }
        }

      }

      &__bg-transparent {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }

}

.application {
  &__loading {
    opacity: 0.3;
  }
}

@media (max-width: 1600px) {
  .page {
    &__application-detail {
      .application-detail {
        max-width: 1240px;
        padding: 35px 20px 12px 20px;
        min-height: 32px;

        .field-box {
          display: flex;

          justify-content: space-between !important;
          flex-wrap: wrap;

          .field {
            margin-bottom: 5px !important;
            margin-right: 0 !important;
            width: 250px !important;
          }
        }

      }
    }
  }
}

@media (max-width: 1200px) {
  .page {
    &__application-detail {
      .application-detail {
        &__cart {
          width: 100%;
          margin-right: 20px;
        }

        .cart {
          &__wrapper {
            padding: 0;
          }
        }

        .field-box {
          .field {
            width: 100% !important;
          }
        }

      }

    }
  }

}

@media (max-width: 900px) {
  .page {
    &__application-detail {
      display: flex;
      flex-grow: 1;

      .application-detail {
        flex-direction: column;
        font-size: 12px;

        h1 {
          line-height: 25px;

          span {
            display: block;
            padding: 5px 0 0 0;

            font-size: 14px;
          }
        }
        .cart {

          &__header {
            display: flex;
            margin-bottom: 20px;
            h1 {
              white-space: nowrap;
              margin: 0!important;
              line-height: 28px;
            }
            .back-btn {
              padding: 0.4rem!important;
              line-height: 1rem;
              margin-right: 10px;
            }
          }
        }
        &__header {
          //background-color: red;
          .header {

            &__wrapper {
              padding-right: 40px;
              .service {
                padding: 15px 0 20px 0;
                font-family: 'Stolzl', Arial, Helvetica, sans-serif;
                color: #373947;
              }

              .descr {
                padding: 15px 0 25px 0;

                .row {
                  display: flex;
                  flex-direction: row;
                  padding: 5px 0;

                  .col-title {
                    font-weight: bold;
                    width: 150px;
                    color: #6C8DA3;
                  }

                  .col-text {
                    width: auto;
                  }
                }

              }

              .field-box {
                display: flex;
                justify-content: flex-start;

                flex-wrap: wrap;

                .field {
                  margin-bottom: 5px;
                  margin-right: 30px;
                  width: 280px;

                  &__label {
                    font-size: 12px;
                    line-height: 12px;
                    color: #6C8DA3;
                    padding-bottom: 2px;
                    display: inline-block;
                  }

                  &__text {
                    width: 100%;
                    height: 42px;
                    //min-width: 20px;
                    line-height: 2.5rem;
                    border: 0.5px solid #2B323A;
                    box-sizing: border-box;
                    border-radius: $border-radius;
                    background-color: #FAFAFA;
                    padding: 0 12px;
                  }
                }
              }

              h1 {
                margin-bottom: 20px;

                span {
                  color: #6C8DA3;
                  font-weight: 450;
                  display: block;
                }
              }
            }
          }


        }

        &__tab {
          .tab {
            &__wrapper {
              font-size: 17px;
              line-height: 1.2rem;
              font-weight: 400;
              margin: 15px 0;

              ul {
                display: flex;
                margin: 0;
                padding: 0;

                li {
                  margin: 0;
                  padding: 0;
                  list-style: none;

                  &:not(:last-child) {
                    margin-right: 40px;
                  }

                  a {
                    color: #BDBDBD;

                    &:hover {
                      color: #15AAB7;
                    }

                    &.active {
                      color: #373947;
                      border-bottom: 3px #008E9A solid;
                    }
                  }
                }
              }

            }
          }
        }

        .field-box {
          .field {
            width: 100% !important;
          }
        }

        &__cart {
          width: 100%;
          //display: none;

          &.visible {
            display: block;
          }

          .cart {
            &__wrapper {
              .service {
                color: #6C8DA3;
                padding-bottom: 0;
              }

              .descr {
                padding-top: 5px;
              }
            }
          }
        }

        &__lent {
          margin-top: 10px;
          width: 100%;
          height: auto;
          border: 0;
          //display: none;


          &.visible {
            display: block;
          }

          .slider {
            margin: 0;
            padding: 0;
            display: flex;
            width: auto;

            &__wrapper {
              margin-bottom: 20px;

              .filter-slider {
                li {
                  a {
                    padding: 3px 10px;
                    font-size: 12px;
                  }
                }
              }
            }
          }

        }

        .lent {

          &__wrapper {
            padding: 0;
            border-width: 0;

            ul {
              padding-right: 0;

              li {
                div:not(.review) {
                  display: flex;


                  .date {
                    width: auto;
                  }

                  .text {
                    white-space: normal;
                  }
                }

                .review {
                  &__header {
                    line-height: 1rem;

                    .name {
                      padding-left: 40px;
                    }
                  }
                }
              }
            }

            .p-scrollpanel {
              font-size: 12px !important;

              .p-scrollpanel-content {

                div {
                  display: flex;
                  justify-content: space-between;

                  .date {
                    width: auto;
                  }

                  .text {
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }

        .message-form {
          position: sticky;
          bottom: 0;
          left: 0;

          &__wrapper {
            background-color: #fff;
            width: 100%;
            padding-bottom: 10px;
          }

          .replay {
            &__form {

              textarea {
                font-size: 12px;
              }
            }

            &__btn_wrapper {
              .p-button {

                .p-button-label {
                  font-size: 14px !important;
                  line-height: 22px;
                }
              }
            }
          }

        }

        .form-btn {
          &__wrapper {
            margin-left: 0;
          }
        }
      }

    }
  }

}
