.chat {
  &__wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__blank {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;

    h2 {
      font-family: 'Stolzl', Arial, Helvetica, sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #9C9C9C;
    }
  }
}
