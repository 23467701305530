.source {
  &.mobile {
    height: 30px !important;

    &.open {
      overflow: visible !important;

      ul {
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:after {
        display: none;
      }

    }

    ul {
      width: 128px;
      flex-wrap: wrap;
      white-space: nowrap;
      height: auto;
      background-color: #fff;
      z-index: 1;

      li {
        margin: 2px;
      }
    }
  }

  &__wrapper {
    overflow: hidden;
    width: 90px;
    height: 28px;
    cursor: pointer;

    &:not(.mobile) {
      &:hover {
        overflow: visible;

        ul {
          background-color: #fff;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:after {
          display: none;
        }
      }
    }

    &:after {
      display: block;
      content: '';
      width: 26px;
      height: 28px;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      pointer-events: none;
    }

    ul {
      height: 26px;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      display: flex;

      li {
        color: #2B323A;
        margin: 0 2px;
        line-height: 24px;
        min-width: 26px;
        min-height: 26px;
        width: 26px;
        height: 26px;

        padding: 0;
        text-align: center;
        list-style: none;
      }
    }
  }
}
