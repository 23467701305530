@import "src/custom/variables";

.application-tag {
  &.mobile-tag {
    padding: 5px 10px 4px 10px !important;
    top: 0;
  }
}

.page {

  &__application-list-mobile {
    display: flex;
    flex-grow: 1;
    font-size: 12px !important;
    .title {
      padding-right: 0!important;
    }
    .mobile {
      background-image: none!important;
      padding:  4px 16px 4px ;
      .p-button-label {
        font-size: 12px!important;
      }
    }

    label {
      font-size: 10px !important;
    }

    .p-dropdown-items, .p-multiselect-item, .p-multiselect, .p-dropdown-label, .p-inputtext, .p-button {
      font-size: 12px !important;
    }

    .application-tag {
      font-size: 10px !important;
      text-transform: uppercase;
    }

    .application-list {
      width: 100%;
      min-height: 32px;
      margin: 50px auto 40px;

      .title {
        padding: 35px 20px 12px 0;
      }

      &__wrapper {
        width: 100%;
        padding: 0 !important;
      }


      &__plate {
        margin-top: 10px;

        .wrapper-btn {
          position: sticky;
          bottom: 0;
          justify-content: flex-end;
          display: flex;
          margin-top: -25px;

          .btn-up {
            margin-right: 20px;
            margin-bottom: 40px;
            width: 60px;
            height: 60px;

            span {
              font-size: 28px;
              line-height: 40px;
            }
          }
        }
      }

      .application-list-filter {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
        font-size: 12px;
        &.position {
          &__bottom {
            .filter {
              &__wrapper {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
              }
              &__item {
                width: 100%;
                .filter-button {
                  width: 100%;
                }
              }
            }
          }
        }
        .filter {
          &__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;

            .filter-button {
              font-size: 12px;
            }
          }

          &__item {
            margin-right: 5px;

            .filter-slider {

              li {
                &.slide1 {
                  padding: 3px 10px;
                }

                a {
                  padding: 3px 10px;
                  font-size: 12px;
                }
              }
            }

            .filter-button {
              &__title {
                padding: 3px 10px;
              }
            }

            /*
            &:first-child {
              order: -2;
            }

            &:last-child {
              order: -1;
            }
            */
          }
        }
      }

      .filter-content {
        &__filter {
          flex-direction: column;

          .text-field {
            margin-right: 0;
          }

          .filter-slider {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }

        &__dashboard {

          ul {
            li {
              font-size: 8px !important;
              line-height: 0.7rem;

              span {
                line-height: 2rem;
                font-size: 30px;
              }
            }
          }
        }
      }

      .application-plate {
        position: relative;

        &__item {
          width: 100%;
          background-color: #fff;
          border: 1px solid #DBDBDB;
          border-radius: 5px;
          padding: 10px 20px;
          margin-bottom: 10px;
          color: #2B323A !important;
          cursor: pointer;

          .application-rating {
            .p-rating-icon {
              cursor: pointer;
            }

          }

          .date {
            padding: 5px 0 0 0;
            font-weight: bold;
          }

          .status {
            padding: 10px 0;
          }

          .descr {
          }

          .row {
            display: flex;
            flex-direction: row;
            padding: 5px 0;

            .col-title {
              font-weight: bold;
              width: 100%;
            }

            .col-text {
              width: 100%;
            }
          }
        }
      }

    }
    .comments-count {
      margin-left: 0;
      &__text {
        span {
          position: relative;
          top:-4px;
        }
      }
    }

  }
}



/*
@media (max-width: 1350px) {
}

@media (max-width: 1023px) {
  .dashboard {
    .filter-content {
      &__dashboard {
        font-size: 8px;
      }
    }
  }

  .application-list {
    &__search {
      justify-content: space-between;

      .text-field {
        width: 100% !important;

        &.word-field {
          padding-top: 18px;
          margin: 0 10px;
        }
      }
    }
  }

}
*/

@media (max-width: 640px) {
  .page {
    &__application-list {
      .application-list {
        margin: 0 auto !important;

        &__search {
          flex-direction: column;

          .text-field {
            &.word-field {
              padding-top: 0;
              margin: 10px 0;
            }
          }

        }

        &__filter {
          padding-top: 20px;
          margin-bottom: 0;

          .p-selectbutton {
            width: 100%;
            display: flex;

            .p-button {
              width: 100%;
            }
          }
        }

        &__wrapper {
          padding: 10px 20px;

          h1 {
            line-height: 25px;

            span {
              display: block;
              padding: 5px 0 0 0;

              font-size: 14px;
            }
          }
        }

        &__table {
          &__wrapper {
            padding: 0 20px 10px 20px !important;
          }
        }

        .filter-content {
          flex-direction: column;

          &__filter {
          }

          &__dashboard {
            margin-top: 0;

            ul {
              padding: 0;
              margin: 0 0 10px;

              li {
                font-size: 12px;
                padding: 0 2px;

                span {
                  img {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .dashboard {

        }

      }
    }
  }
}

