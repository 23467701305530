.p-datatable {
  table-layout: fixed;

  .p-sortable-column {
    white-space: nowrap;

    &.p-highlight {
      color: #2B323A;
    }

    .p-sortable-column-icon {
      color: #2B323A !important;
      font-size: 10px;
    }

    .asc:before, .pi-sort-amount-up-alt:before {
      content: "\e9a2";
    }

    .desc:before, .pi-sort-amount-down:before {
      content: "\e9a0";
    }

  }

  th.date-col {
    width: 120px;
  }

  th.nps-col {
    width: 65px;
  }

  th.chat-col {
    width: 70px;
  }

  tr {
    cursor: pointer;
    &:hover {
      background-color: #e9ecef;
    }

  }

  td.date-col {
    padding-top: 20px !important;
    width: 50px;
    white-space: normal !important;
    .number-col {
      span {
        white-space: nowrap;
        display: flex;
        color: #2B323A;
      }

      font-size: 12px;
      color: #aba9a9;
    }
  }

  td.nps-col {
    vertical-align: top;

    div {
      //margin-top: 4px;
    }
  }

  .service-col {
    white-space: normal !important;
    width: 130px !important;
  }

  .numApp-col {
    width: 80px;
  }

  td.numApp-col {
    padding-top: 0 !important;
  }

  .status-col {
    width: 200px;
    min-width: 150px;
    white-space: normal !important;

    .application-tag {
      margin-top: 4px;
      width: 185px;
    }

    div {
      text-align: center;
    }
  }

  .rating-col {
    width: 120px;
    .nps {
      margin-top: 3px;
    }
    .application-rating {
      margin-top: 3px;
    }
  }

  /*
  td.rating-col {
    padding-top: 0 !important;
  }
  */

  .auto-col {
    width: 250px;
  }

  .auto-col div:first-child {
    position: relative;
    top: 2px;
  }

  .auto-col div span:first-child {
    display: inline-block;
    max-width: 80%;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .auto-col div span:last-child {
    width: 30%;
    overflow: hidden;
    display: inline-block;
  }

  .manager-col{
    overflow: auto!important;
    white-space: normal!important;
  }
  .client-col {
    width: 140px;

    div {
      padding-right: 10px!important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .p-datatable-thead {
    tr {
      th {
        padding: 15px 10px;
      }
    }
  }

  .p-datatable-tbody {

    tr {
      color: #2B323A;

      td {
        padding: 15px 10px 15px 0!important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2rem;
        vertical-align: top;
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.application-list {
  .p-datatable-tbody {
    .p-datatable-row {
      &:hover {
        background-color: #e9ecef;
      }
    }

    tr {
      color: #2B323A;

      td {
        cursor: pointer;
      }
    }
  }
  .ico {
    &__wrapper {
      display: flex;
      margin-top: -2px;
      height: 17px;
      justify-content: center;
    }
  }
  .comments-count {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-top: 4px;

    &__ico {
      margin-right: 3px;
    }

    &__text {
      display: block;
      min-width: 16px;
      height: 16px;
      padding: 0 4px;
      border-radius: 16px;
      background-color: #008E9A;
      color: #fff;
      font-size: 12px;
      text-align: center;
      position: relative;

      span {
        position: relative;
        top: -1px;
      }
    }
  }
}

/*
@media (max-width: 820px) {
  .page {
    &__application-list {}

    .application-list {
      font-size: 12px;
      max-width: 100%;
      width: 100%;
      &__filter{}
      &__wrapper{
        width: 100%;
        padding: 0!important;
      }
    }

  }
}
*/
