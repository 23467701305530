.react-dadata {
  &__input {
    box-sizing: border-box;
    border: 0.5px solid #343546 !important;
    border-radius: 0;
    outline: none;
    width: 100%;
    height: 38px;
    padding: 0 10px;
    font-size: 16px;
    display: block;
    &:focus {
      box-shadow:none;
    }

  }
  &__suggestion{
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
