.side-bar{

  padding: 20px 75px;
  width: 40%;
  &.mobile {
    padding: 20px;

    .text-field {
      width: 100%;
    }

    &__title {
      h3 {
        color: #373947;
        font-size: 24px !important;
      }
    }
  }

  .p-sidebar-icons {
    padding-bottom: 14px;

    &:hover {
      .close-text {
        color: #008E9A !important;
      }

      .p-sidebar-icon {
        border-color: #008E9A !important;
        color: #008E9A !important;
      }
    }

    .close-text {
      margin-right: 10px;
      color: #7A7A7A;
    }
  }

  .text-field {
    width: 70%;
  }

  .textarea-field {
    .p-inputtextarea {
      line-height: 20px;
    }
  }

  button,
  button:active,
  button:hover,
  button:focus {
    outline: none !important;
    box-shadow: none !important;
    transition: none !important;
  }

  .p-disabled {
    &:hover {
      pointer-events: none;
    }
  }


  .p-sidebar-icon {
    border: 1px solid rgba(0, 0, 0, 0.4);

    .p-sidebar-close-icon {
      font-size: 9px;
    }
  }

  &__title {
    h3 {
      color: #373947;
      font-size: 32px !important;
      line-height: 28px;
    }
  }

  &__body {
    margin-top: 25px;
  }
}

.compare-side-bar {
  padding: 20px 75px;
  width: 100%;
  height: auto;
}