.DayPicker {
  &_transitionContainer {
    border-radius: 5px 5px 0 0;
  }

  &__withBorder {
    box-shadow: 0 2px 6px rgba(166, 166, 166, 1), 0 0 0 1px rgba(166, 166, 166, 1);
    border-radius: 5px 5px 0 0;
  }
}

.DayPickerKeyboardShortcuts {
  &_buttonReset {
    display: none;
  }
}

.DayPickerNavigation {
  &_button {
    border-radius: 30px;
  }
}

.DateRangePicker {

  .DateRangePickerInput {
    background-color: #008E9A;

    &_arrow_svg {
      fill: #fff;
      width: 17px;
      height: 17px;
    }

    .DateInput {
      background-color: #008E9A;
      border-radius: 30px;

      &_fangStroke {
        stroke: #a6a6a6;
      }

      &_input {
        background-color: #008E9A;
        color: #fff!important;
        height: 38px;
        cursor: pointer;
        font-size: 16px!important;
        &::placeholder {
          opacity: 1;
          color: #fff;
        }

        &__focused {
          border-color: #01B3C2;
        }

        &#endDate {
          border-radius: 0 30px 30px 0;
          text-align: right;
          padding-right: 20px;
        }

        &#startDate {
          border-radius: 30px 0 0 30px;
          padding-left: 20px;
        }

      }
    }

    &__withBorder {
      border: 0.5px solid #008E9A;
      border-radius: 30px;
    }
  }

  .DateInput {
    width: 120px;
  }

  .DateInput_input {
    font-family: "Stolzl Book", "Stolzl", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7rem;
  }

  .CalendarDay {
    &__selected_start {
      background-color: #008E9A;
    }

    &__selected_end {
      background-color: #008E9A;
    }

    &__selected_span {
      border-color: #01B3C2;
      background-color: #01B3C2;
    }
  }

  input {
    height: 42px;
    width: 100%;
  }
}

.disabled {
  .DateRangePicker {
    .DateRangePickerInput {
      background-color: #ccc;

      &__withBorder {
        border: 0.5px solid #CCCCCC;
      }

      &__disabled {
        background-color: #ccc;
        font-style: normal;
      }

      .DateInput {
        background-color: #CCC;

        &_input {
          background-color: #ccc;
          font-style: normal;
          color:#fff;

        }


      }
    }
  }
}
.page {
  &__application-list-mobile,
  &__analytics-mobile,
  &__review-list-mobile {
    .DateRangePicker {
      .DateRangePickerInput {
        .DateInput {
          width: 90px;

          &_input {
            font-size: 12px!important;
            height: 28px;
            &#endDate {
              padding-right: 10px;
            }

            &#startDate {
              padding-left: 10px;
            }


          }
        }
      }
    }

  }
}

