.status {
  position: relative;

  .application-tag {
    margin-right: 0;
    width: 235px;
    text-align: center;
    &__button {
      cursor: pointer;
    }
  }

  &__button {
    position: relative;
    margin-left: 8px;
    top: 6px;
    cursor: pointer;
  }

  &__item {
    cursor: pointer;
    .application-tag{
      width: 100%;
      margin: 8px 0;
    }
  }

  &__dropdown {
    position: absolute;
    background: #FFFFFF;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 16px 24px;

    left:-24px;
    z-index: 10;

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 235px;
    }
  }

  //pi-angle-down
}
