@import 'src/custom/variables';

.dashboard {
  &__sticky {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
    border-radius: $border-radius;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 10px 15px 0 15px;
    z-index: 5;

    ul {
      padding: 0;
    }
  }
}

.filter-content {
  &__dashboard {
    display: inline-flex;
    justify-content: flex-start;
    position: relative;

    .more {
      font-size: 11px;
      color: #008E9A;
      position: absolute;
      bottom: -25px;
      right: 20px;

      span {
        position: relative;
        top: 1px;

        i {
          font-size: 10px;
          line-height: 12px;
        }
      }

    }

    .block {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      height: 80px;
      margin: 12px 20px 0 0;
      border: 1px dashed #C1C0C0;
      border-radius: 10px;

      li {
        position: relative;
        list-style: none;
        display: flex;
        flex-direction: column;
        min-width: 150px;
        font-size: 12px !important;
        justify-content: center;
        align-items: center;
        padding: 0 30px 0 20px;
        border-left-width: 0;
        line-height: 14px;
        text-align: center;

        span:first-child {
          padding-bottom: 5px;
        }

        .CountUp {
          &__counter {
            top: 0 !important;
          }
        }

        &:nth-child(2) {
          background: url('../../../../images/dashboard/dashboard_dashed_corner.svg') right top no-repeat;
          border-right-width: 0;
        }

        &:first-child {
          color: #fff;
          border-radius: 9px 0 0 9px;
          min-width: 150px;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -1px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 50px 30px;
            border-color: transparent transparent #fff transparent;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -1px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 30px 50px 0;
            border-color: transparent #fff transparent transparent;
          }

        }

        &:last-child {
          border-radius: 0 9px 9px 0;
          padding-right: 20px;
        }

        span:first-child {
          position: relative;
          top: -3px;
        }

        span:last-child {
          position: relative;
          top: -3px;

          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 22px;
          font-weight: 500;

          img {
            display: block;
            margin-right: 10px;
          }
        }
      }

      &__block1 {
        li {
          &:first-child {
            background-color: #6FCF97;
          }
        }
      }

      &__block2 {
        li {
          &:first-child {
            background-color: #1CB3E4;
          }
        }
      }

      &__block3 {
        li {
          &:first-child {
            padding-right: 20px;
            background-color: #FDAC4D;
            border-radius: 9px;
            align-items: center;

            &:before, &:after {
              display: none;
            }
          }
        }
      }

      &__block4 {

        img {

          path {
            fill: #fff;
          }

          //width: 100px;
        }

        li {
          &:first-child {
            padding-right: 20px;
            background-color: #EB5757;
            border-radius: 9px;
            align-items: center;

            &:before, &:after {
              display: none;
            }
          }
        }
      }

      &__block5 {
        li {
          &:first-child {
            padding-right: 20px;
            background-color: #FFF;
            border-radius: 9px;
            align-items: center;
            color: #373947;

            &:before, &:after {
              display: none;
            }
          }
        }
      }

    }
  }
}
