@import "src/custom/variables";

.tag {
  &__auto {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    top:1px;
    left:1px;
    li {
      list-style: none;
      margin-inline-end: 8px;
      margin-bottom: 8px;
      white-space: normal;

      &.OkItemTag {
        white-space: nowrap;
        font-family: $font-family;
        font-size: 14px;
        color: rgba(48, 48, 48, 0.7) !important;
        line-height: 38px;
        background-color: transparent;
        border: 1px solid #2F80ED !important;
        border-radius: 0;
        height: 40px;
        padding: 0 1.5rem !important;
      }
    }
  }
}

.MyTag {
  font-family: $font-family;
  width: auto !important;
  min-width: 150px;

  &.ant-select-loading {
    opacity: 0.1;
  }

  .OkItemTag {
    position: relative;
    top:-1px;
    font-family: $font-family;
    font-size: 14px;
    color: rgba(48, 48, 48, 0.7) !important;
    line-height: 38px;
    background-color: transparent;
    border: 1px solid #eef0f0 !important;
    border-radius: 0;

    height: 40px;
    padding: 0 1.5rem !important;

    .anticon-close {
      margin-left: 6px;

      &:hover {
        color: #EB5757;
      }
    }

    &:hover {
      .anticon-close {
        visibility: visible;
      }
    }

    .anticon-close {
      visibility: hidden;
    }
  }

  .ant-select-selector {
    height: auto !important;
    cursor: pointer !important;
    margin: 0;
    padding: 0;
  }

  .ant-select-selection-search {
    min-width: 150px !important;
    width: 150px !important;
    color: #AFB0B5;

    height: 42px;
    margin: 0;

    .ant-select-selection-search-input {
      border: 1px solid #373947 !important;
      min-width: 100px;
      width: 150px;
      overflow: hidden;

      cursor: pointer !important;
      line-height: 40px;
      height: 40px !important;
      padding: 0.4rem 1.5rem !important;

      &:hover {
        border: 1px solid #008E9A !important;
      }

      &:focus {
        border: 1px solid #008E9A !important;
      }

    }
  }

  .ant-select-selection-overflow-item {
    margin-bottom: 8px;

    .ant-select-selection-item {
      .ant-select-selection-item-content {
      }

      .ant-select-selection-item-remove {
        visibility: hidden;
      }

      &:hover {
        .ant-select-selection-item-remove {
          visibility: visible;
        }
      }
    }
  }
}

.MyTagDropdown {
  font-family: $font-family;
  min-width: 250px !important;
}
