@import "src/custom/variables";

html, body, #root {
  height: 100%;
  line-height: 24px;
}

.page {
  &__review-detail {
    .title {
      max-width: 1690px;
      margin: 0 auto;
      padding: 40px 0 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .form-btn__wrapper {
        display: block;
        position: relative;
        margin: 0 10px 0 0;

        .back-btn {
          padding: 0.4rem 0.4rem;
        }
      }

    }


  }

  &__review-list {
    max-width: 1690px;
    min-height: 32px;
    padding: 0 20px;
    margin: 0 auto !important;
    font-size: $font-size !important;

    .title {
      margin: 0 auto;
      padding: 40px 0 12px;
      position: relative;
    }

    .p-button {
      padding: 0.4rem 1.5rem;

      &.excel {
        position: absolute;
        right: 0;
        bottom: 2px;

        background: transparent url("./../../../../images/excel_ico.svg") 13px 50% no-repeat !important;
        padding-left: 48px;

        &:hover {
          background: #01B3C2 url("./../../../../images/excel_ico2.svg") 13px 50% no-repeat !important;
        }
      }

      .p-button-label {
        font-family: $font-family;
      }

      &.p-button-outlined {
        background: #FFFFFF;
        border: 1px solid #008E9A;
        color: #008E9A !important;
        box-sizing: border-box;
        border-radius: 2px !important;

        &:hover {
          background: #008E9A;
          color: #fff !important;
          border: 1px solid #008E9A !important;
        }
      }
    }
  }

  .review {
    &__images-list {
      display: flex;
      flex-wrap: wrap;
      padding: 14px;
      background-color: #DBDBDB;
      border-radius: 8px;
      margin: 16px 0;

      .image {
        padding: 10px;
      }
    }
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    color: #303030;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #303030;
  }
}


@media (max-width: 1600px) {
  .page {
    &__review-list, &__review-detail {
      .title {
        max-width: 1200px;

        padding: 40px 0 12px;
      }
    }
  }
}

