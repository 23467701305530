@import "src/custom/variables";

.page {
  &__company-list {
    .company-list {
      max-width: 1690px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .title {
      padding: 40px 0 0 0;
    }

    .p-datatable {
      color: #2b323a;
      line-height: 19.2px;
    }

    .status-col {
      width: 200px;
    }

    .platform-col {
      width: 200px;
    }

    .progress-col {
      width: 150px;
    }

    .branch {
      div {
        padding-left: 30px;

        background: url("../../../images/branch_ico.png") 0 5px no-repeat;
        background-size: 25px 12px;

      }
    }
    .check {
      vertical-align: top;
      margin-left: 30px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        list-style: none;
        align-items: center;
      }
    }

    .progress {
      padding-top: 2px;
      padding-bottom: 12px;

      ul.scale {
        display: flex;

        li {
          &.red{
            background-color: #EB5757;
          }
          &.green{
            background-color: #6FCF97;
          }

          display: flex;
          list-style: none;
          align-items: center;

          margin-right: 3px;
          width: 4px;
          height: 12px;
          border-radius: 2px;
          background-color: #D9D9D9;

        }
      }
    }

    .platform {

      .rating {
        display: flex;
        margin-left: 25px;
        align-items: flex-start;
        position: relative;
        top:-2px;

        .pi-star {
          color: #ffc547;
          font-size: 18px;
          line-height: 24px;
          margin-right: 5px;
        }

        .value {
          display: flex;
          height: 24px;
          align-items: center;
          width: 25px;
        }
      }
    }
  }


  .company-table {
    padding-bottom: 50px;
    margin-top: 30px;

    &__header {
      padding: 5px 30px 20px 0;
    }
  }

  .table {
    &__wrapper {

      .loader {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FFF;
        opacity: 0.5;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }


  tbody {
    tr {
      cursor: default;

      td {
        border-bottom: 1px solid #DBDBDB;
        padding: 8px 5px;
        vertical-align: top;
      }

      &.align-top {
        td {
          vertical-align: top;
        }
      }

      &.first-row {
        td {
          border-bottom: 1px solid #373947 !important;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}


/*.status-tag {
  font-size: 14px;
  line-height: 17px;
  vertical-align: top;
  padding: 5px 13px;
  display: inline-block;
  border-radius: 0;
  background-color: #E7F8EE;
  color: #000;
  min-width: 108px;
  text-align: center;

  &.yellow {
    color: #ffa400;
    background-color: #feffd9;
  }

  &.green {
    color: #38A749;
    background-color: #E7F8EE;
  }

  &.blue {
    color: #558be8;
    background-color: #d9e6f9;
  }

  &.gray {
    color: #828282;
    background-color: #F2F2F2;
  }
  &.red {
    color: #EB5757;
    background-color: #FFECEC;
  }

  &.grey {
    color: #3e4f72;
    background-color: #dfe1e6;
  }
}*/

@media (max-width: 1600px) {
  .page {
    &__company-list {
      .company-list {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
      }

      .title {
        max-width: 1240px;
        padding: 40px 0 0 0;
      }
    }
  }
}
