@import "src/custom/variables";

.loading_tmp {
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100% !important;
  height: auto !important;
}
*/

.wrapper {
  align-self: center;
}


.dashboard {
  width: 100%;
  padding-top: 20px;
  &__loading {
    opacity: 0.2;
  }
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

/* статус */
.p-tag {
  background-color: transparent;
  text-align: left;
}

.application-tag {

  &.mobile-tag {
    padding: 5px 10px 4px 10px !important;
    top: 0;
  }

  color: #256029;
  font-size: 12px;
  line-height: 17px;
  vertical-align: top;
  padding: 8px 14px;
  display: inline-block;
  border-radius: 0;
  //margin-right: 30px;
  width: 100%;
  //text-transform: capitalize;
}


.status {
  &-inwork {
    background-color: #D4F1D5;
  }
}

/* рейтинг */

.application-rating {
  .p-rating-icon {
    font-size: 16px;
    margin-left: 2px;

    &.pi-star {
      color: #FFC547;
    }

    &.pi-star-o {
      color: #C4C4C4;

      &:before {
        content: "\e937";
      }
    }
  }
  .p-rating-star-on {

  }
}

.application-rating:not(.p-disabled):not(.p-readonly) {
  .pi-star-o:hover {
    color: #C4C4C4;
  }

  .pi-star:hover {
    color: #FFC547;
  }

}

/* пагинация */
.p-paginator {
  padding-top: 50px;
}

.p-paginator-element {
  border: 0 !important;
  color: #90AFC4 !important;
}

.p-paginator-pages {
  .p-paginator-element {
    color: #2B323A !important;

    &.p-highlight {
      background-color: #01B3C2 !important;
      color: #FFF !important;
    }
  }
}


.page {
  &__application-list {
    display: flex;
    flex-grow: 1;

    .new-appl {
      position: absolute;
      left:270px;
      top:33px;
    }
    .title {
      padding: 40px 0 12px;
      position: relative;
    }
    .p-button {
      padding: 0.4rem 1.5rem;
      &.excel {
        position: absolute;
        right: 0;
        bottom: 2px;
        background: transparent url("./../../../../images/excel_ico.svg") 13px 50% no-repeat!important;
        padding-left: 48px;

        &:hover {
          background: #01B3C2 url("./../../../../images/excel_ico2.svg") 13px 50% no-repeat!important;
        }
      }

      &.p-button-outlined {
        background: #FFFFFF;
        border: 1px solid #008E9A;
        color: #008E9A !important;
        box-sizing: border-box;
        border-radius: 2px !important;

        &:hover {
          background: #008E9A;
          color: #fff !important;
          border: 1px solid #008E9A !important;
        }
      }
    }
    .application-list {
      width: 100%;
      max-width: 1690px;
      min-height: 32px;
      padding: 0 20px;
      margin: 0 auto !important;

      &__table {
        width: 100%;
        height: 100%;
      }

      &__wrapper {
        padding: 0 0 40px 0;
        height: 100%;
        h1 {
          span {
            color: #6C8DA3;
            font-weight: 450;
            display: inline-block;
            padding-left: 15px;
          }
        }
      }

      &__bg-transparent {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}

.application {
  &__loading {
    opacity: 0.3;
  }
}

.application-list-filter {
  padding-top: 13px;
  .list-filter {
    &__pager-size {
      flex-grow: 1;
      justify-content: flex-end;
      color: #008E9A;
      font-size: 16px;
    }
  }

  .filter {
    &__wrapper {
      .filter-slider, .filter-button {
        font-size: 16px;
      }
    }
    &__item {
      &.word-field {
        flex-basis: 30%;
        .text-field {
          width: 100%;
        }
        .p-input-icon-left {
          width: 100%;
        }
        input {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .page {
    &__application-list {
      display: flex;
      flex-grow: 1;

      .application-list {
        max-width: 1240px;
        min-height: 32px;
      }
    }
  }

}
