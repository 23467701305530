.tabs {
  font-size: 24px;
  line-height: 1.2rem;
  font-weight: 700;



  ul {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 42px;

      &:not(:last-child) {
        margin-right: 40px;
      }

      a {
        color: #BDBDBD;
        &:hover {
          color: #15AAB7;
        }
        &.active {
          color: #373947;
        }
      }
    }
  }

}
