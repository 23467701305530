.DatePickerWrapper{
  position: relative;

  .pickerPanelWrapper {
    position: absolute;
    left:0;
    top:55px;
    z-index: 5;
  }

}

