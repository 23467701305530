
.application-list, .review-list {
  &__plate, &__wrapper {
    margin-top: 10px;

    .wrapper-btn {
      position: sticky;
      bottom: 0;
      justify-content: flex-end;
      display: flex;

      .btn-up {
        margin-right: 20px;
        margin-bottom: 80px;
        width: 60px;
        height: 60px;

        span {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }
}

