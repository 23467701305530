@import "src/custom/variables";

.page {
  &__login {
    .form {
      max-width: 740px;
      width: 740px;
      min-height: 32px;
      background-color: #fff;
      margin: 50px auto 120px;
      padding: 20px 50px 40px 35px;
      display: flex;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
      border-radius: $border-radius;

      .submit-btn {
        padding: 0.7rem 3.5rem;
      }

      &__bg-transparent {
        background: transparent !important;
      }

      &__img{
        padding-right: 35px;
        padding-top: 17px;
      }

      .p-inputtext {
        width: 278px;
        height: 40px;
      }

      .text-field {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}

.form .successText, .form .errorText {
  display: flex;
  align-items: center;
  font-size: 25px;
  height: 135px;
  font-weight: 600;
  text-align: center;
  color: #008E9A;
}

.form .errorSecondText {
  text-align: center;
  font-size: 18px;
  color: #2B323A;
}

.form .errorText {
  color: #C55469;
}

.form .inputTextError {
  color: #C55469;
  font-size: 10px;
  position: absolute;
  right: 0px;
  top: -20px;
}

.successForm div,
.errorForm div {
  text-align: center;
}

.successForm div a button,
.errorForm div a button {
  margin-top: 10px;
}

.form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}


.wrapper_ico_eye {
  position: relative;
  padding-right: 20px !important;
}

.ico_eye {
  position: absolute;
  right: 7px;
  top: 7px;
  color: #008E9A;
  cursor: pointer;
}
.checkbox-label{
  padding-left:10px;
  cursor: pointer;
}
@media (max-width: 415px) {
  .page {
    &__login {
      .form {
        padding: 20px 20px 40px 20px;
        width: auto;

        .submit-btn {
          right: 0;
        }

        &__img{
          display: none;
        }
      }
    }
  }
}

