$font-family: 'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif;
$font-family2: 'Stolzl', Arial, Helvetica, sans-serif;
$font-size: 16px;
$font-weight: 400;

$border-radius: 2px;
$color-text: rgba(48, 48, 48, 0.7);
$primary-color: #2B323A;
$border-color: #A6A6A6;

.page {
  &__review-list-mobile {
    padding: 0 20px;
    line-height: 1.3rem;

    .source {
      &__title {
        white-space: normal;
        align-items: flex-start;

        .ico {
          position: relative;
          top: 2px;
        }
      }
    }

    .title {
      padding: 35px 0 12px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .mobile {
        background-image: none!important;
        padding:  4px 16px 4px ;
        .p-button-label {
          font-size: 12px!important;
        }
      }
    }

    h2 {
      font-size: 20px;
      color: #373947;
    }

    .review-filter {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
      font-size: 12px;

      .filter {
        &__wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          width: 100%;
        }

        &__item {
          margin-right: 5px;

          .filter-button {
            &__title {
              padding: 3px 10px;
            }
          }

          &:first-child {
            order: -2;
          }

          &:last-child {
            order: -1;
          }
        }
      }
    }

    .review-charts {
      overflow: scroll;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 15px;

      &__wrapper {
        display: flex;
        width: 1600px;
      }

      .chart {
        &__item {
          width: 300px !important;
        }
      }
    }

    .review-list {
      padding: 0;

      &__header {
        display: flex;

        h2 {
          font-size: 24px;
        }

        .count {
          margin-top: 21px;
          margin-left: 17px;
          color: #008E9A;
        }
      }

      &__table {
        padding-top: 0;
      }

      &__item {
        border: 1px solid #C1C0C0;
        border-radius: 4px;
      }

      .sort {
        &__wrapper {
          padding-bottom: 20px;

          .p-dropdown {
            width: 100%;
          }
        }
      }

      .item {
        &__status {
          align-self: flex-start;
          flex-direction: column;
          align-items: normal;

          .status {
            margin-left: 0;
            margin-bottom: 10px;
            text-align: center;
          }
        }

        &__rating {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            display: block;
            position: relative;
          }

          .item_subsource-icon {
            position: absolute;
            right: -3px;
            bottom: 2px;
          }
        }

        &__wrapper {
          padding: 20px;
          display: flex;
          flex-direction: column;
        }

        &__body {
          padding-top: 20px;

          &.hide {
            max-height: 145px;
          }

          .shadow {
            top: 95px;

          }

        }

        .replay {

          &__btn_wrapper {
            bottom: -10px;
          }

          &__form {
            textarea {
              font-size: 14px;
              padding: 10px;
            }
          }
        }

        &__jeremiad-btn {
          margin-top: 20px;
        }

        &__answer-btn, {
          margin-top: 20px;

          .p-button {
            color: #008E9A;
            width: 100%;

            &:hover {
              color: #008E9A;
              background-color: #fff !important;
            }
          }
        }

        .p-sidebar {
          &.p-sidebar-right {
            width: 100%;
          }
        }


      }

      .p-button {
        //padding: 9px 35px 11px 35px;
        padding: 0.4rem 1.5rem;

        .p-button-label {
          font-family: $font-family;
          font-size: 14px;
        }

        &.p-button-outlined {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #008E9A;
          color: #008E9A !important;
          box-sizing: border-box;
          border-radius: 2px !important;

          &:hover {
            background: #008E9A !important;
            color: #fff !important;
            border: 1px solid #008E9A !important;
          }
        }
      }
    }

  }


}
