.p-toast {
  width: 500px;

  &.mobile {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    right: 0
  }

  .p-toast-detail {
    line-height: 1.2rem;
  }

  .p-toast-icon-close {
    min-width: 2rem;

    &:hover {
      border-radius: 0;
      background-color: transparent !important;
    }
  }
}
