.page {
  &__analytics-mobile {
    .analytics-footer {
      margin-top: 25px;
      .analytics-carousel {
        width: 100%;
      }
    }
  }
}

.analytics-footer {
  margin-left: 8px;
  .analytics-carousel {


    ul {
      display: flex;
      padding: 0;
      margin: 0;
      justify-content: center;

      li {
        margin: 0 8px;
        padding: 0;
        list-style: none;
        cursor: pointer;

        img {
          display: block;
          opacity: 0.5;
          &.active {
            opacity: 1;
            cursor: default;
          }
          &:hover {
            opacity: 1;

          }
        }

      }
    }
  }

}
