.split-button {
  padding: 7px 25px;
  color: #fff;
  background-color: #EB5757;
  border: 1px solid #EB5757;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  border-radius: 4px;

  &__wrapper {
    position: relative;
  }

  .button-icon-right {
    margin-left: 8px;
  }

  .label {
    flex: 1 1 auto;
    line-height: 24px;
  }

  &__dropdown {
    position: absolute;
    width: 200px;
    display: none;
    z-index: 1;
    color: #fff;
    background-color: #fff;

    ul {
      padding: 0;
      margin: 0;

      box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
      border-radius: 4px;

      li {
        color: #373947;
        padding: 10px;
        list-style: none;
        cursor: pointer;

        &:hover {
          background-color: #F1F1F1;
        }
      }
    }

    &.active {
      display: block;
    }

  }
}