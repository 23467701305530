.side-bar {
  .sources-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin: 0 0 35px;

    label {
      width: 40%;
      overflow: hidden;
      height: 60px;
      font-size: 12px;
      border: 1px solid #008E9A;
      border-radius: 10px;
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0 20px 20px 0;
      padding: 0 0 0 10px;
      cursor: pointer;
    }
    li {
      display: flex;
      align-items: center;

      .p-checkbox {
        margin-right: 10px;
      }

      img {
        margin-right: 10px;
      }
    }

  }
}

.place-setup {

  border: 1px solid #C1C0C0;
  border-radius: 8px;
  width: 100%;
  //padding: 8px 40px;

  &__header {
    padding: 8px 10px 0 40px;
    position: relative;

    .delete-btn {
      position: absolute;
      top:8px;
      right:8px;
    }

    .header {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        cursor: pointer;
        padding: 10px 20px;

        &.add-link {
          color: #008E9A;
        }
      }

      &__active {
        border-bottom: 2px solid #008E9A;
      }

    }
  }

  &__body {
  }

  .body {

    border-top: 1px solid #C1C0C0;

    display: none;
    padding-top: 20px;

    .block {

      &__form {
        padding: 8px 40px 0 40px;

      }

    }


    &__active {
      display: flex;
    }
  }
}