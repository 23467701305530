.okSelect {
  &.ant-select-single {
    height: 40px;
  }
  .ant-select-arrow, .ant-select-clear {
    color: #008E9A;
  }

  .ant-select-arrow {
  }

  .ant-select-selector {
    min-height: 40px;
    .ant-select-selection-search {
      input {
        height: 38px;
        background-color: red;
      }
    }
  }

  .ant-select-selection-overflow-item {

    .ant-tag {
      font-size: 14px;
      background: rgba(0, 0, 0, 0.06);
      border: 1px solid transparent;
      height: 32px;
      align-items: center;
      padding-inline-start: 12px;
      padding-inline-end: 10px;
      margin-top: 2px;
      margin-bottom: 2px;
      margin-right: 4px;
      display: flex;

      .pi-star {
        position: relative;
        top: -2px;
      }

    }

  }

  .ant-select-selection-item {
    height: 33px;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;

    .ant-select-selection-item-content {
      padding-inline-end: 4px;
    }
    .ant-select-item-option-content {
      background-color: red;
    }
  }
  .pi-star {
    color: #FFC547;
    font-size: 14px;
    margin-left: 5px;
  }
}

.ant-select-dropdown {
  .ant-space {
    .ant-space-item {
      .pi-star {
        color: #FFC547;
      }
    }
  }
}

label {
  font-size: 12px;
  color: #90AFC4;
}
