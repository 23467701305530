.page {
  &__settings {

    .form {
      .block {
        h3 {
          font-size: 24px;
          line-height: 32px;
          margin: 20px 0 15px 0;
        }

        .content {
          width: 640px;

          &__complete {
            margin-bottom: 11px;
          }

          .p-autocomplete {
            width: 100%;

            .p-button {
              position: relative;
              right: 1px;
              background-color: #fff;
              border: 0.5px solid #343546 !important;
              border-left-width: 0 !important;
              line-height: 0.7rem;

              &:hover {
                background-color: #fff !important;
                border-left-width: 0 !important;
              }
            }
          }

          &__switches {
            padding: 0;
            margin: 0;

            li {
              list-style: none;
              padding: 0;
              margin: 8px 0;
              display: flex;
              align-items: center;
              &:last-child {
                margin-bottom: 0;
              }
              button {
                margin-right: 20px;
              }
            }
          }

          &__text-field {
            position: relative;
            width: 100%;
            //margin: 36px 0 11px 0;

            .p-inputtext, .p-dropdown {
              width: 100%;
            }

            &.small {
              .p-inputtext, .p-dropdown {
                width: 50%;
              }
            }

          }
        }
      }
    }
  }
}

.button {
  &__wrapper {
    .button {
      margin-right: 10px;
    }
  }
}

.message-block {
  position: absolute;
  text-align: right;
  font-size: 11px;
  bottom:-20px;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  max-width: 600px;
  &__error {
    color: #dc3545;
  }
}

.settings-tabs {

  ul {
    padding: 0;

    margin: 15px 0 25px 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #C1C0C0;

    li {
      position: relative;
      bottom: -2px;
      padding: 0;
      margin: 0;
      list-style: none;

      &:has(>a.active) {
        border-bottom: 2px solid #008E9A;
      }

      a {
        color: #373947;
        padding: 0 25px;
        line-height: 44px;
      }
    }
  }
}

.rating {
  &__item {
    .pi-star {
      color: #FFC547;
    }
  }
}

.okSwitch {
  &.ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
    background-color: #32CC32;
  }
}

//TODO убрать в глобальные стили
label {
  line-height: 12px;
  font-size: 12px;
  display: block;
  padding-bottom: 3px;
}