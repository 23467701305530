.page {
  &__settings {
    max-width: 1690px;
    min-height: 32px;
    padding: 0 20px;
    margin: 0 auto !important;
    font-size: 16px;

    td, th {
      min-height: 45px;
      height: 45px;

      &.checkbox {
        width: 25px;
      }
      &.count{
        color:#008E9A;
      }
    }

    .title {
      padding: 40px 0 12px 0;
    }

    /*
    &.tag-table {
      width: auto !important;
      padding-bottom: 50px;
    }
    */

    &.tag-table {
      padding-bottom: 50px;

      &__header {
        padding: 5px 30px 20px 0;
      }
    }

    .table {

      &__wrapper {

        .loader {
          position: absolute;
          left: 0;
          top: 0;
          background-color: #FFF;
          opacity: 0.5;
          z-index: 2;
          width: 100%;
          height: 100%;
        }

      }
    }
    .p-datatable-wrapper {

      table {
        max-width: 700px;
        table-layout: auto;
      }
    }
    .p-datatable {

      tbody{
        tr {
          td {
            &.checkbox {
              text-align: center;
            }
            &.action {
              text-align: center;
            }
          }
          cursor: default;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }


  }

}

@media (max-width: 1600px) {

  .page__settings {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .tag-table {
    width: auto !important;
  }

}

.delete-btn {
  cursor: pointer;
  &:hover {
    color: #EB5757;
  }
}

.button {
  &__wrapper {
    display: flex;

    margin: 25px 0 35px;
    .ant-dropdown-trigger {
      margin: 0 15px;
    }
  }
}

.inline-edit-input {
  border: 1px solid #fff;
  width: 100%!important;
  &:focus, &:active, &:hover {
    outline: 1px solid #ccc;
  }
}