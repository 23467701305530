@import "../list/Review";

.review-charts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 23px;
}

.chart {
  &__item {
    width: 24%;

    fieldset {
      border: 1px solid #C1C0C0;
      box-sizing: border-box;
      border-radius: 8px;
      padding-top: 13px;
      height: 220px;
    }
  }

  &__header {
    height: 70px;
    background-color: inherit;
    padding-left: 5px;
    display: flex;

    .header {
      &__left-side {
        .number-top {

          font-size: 38px;
          line-height: 44px;
          margin-bottom: 5px;
          font-style: $font-family2;
          color: #303030;
          display: flex;
          align-items: flex-end;

          span {
            font-style: $font-family;
            font-size: 24px;
            line-height: 34px;
            color: $color-text;
            margin-left: 15px;
          }

        }

        .number-bottom {
          font-size: 24px;
          line-height: 14px;

        }
      }

      &__right-side {
        padding-top: 5px;
        padding-left: 10px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .color {
      &_green {
        color: #6FCF97;
      }

      &_red {
        color: #EB5757;
      }
    }

    .star {
      top: -2px;
      position: relative;
      width: 39px;
      height: 39px;
      opacity: 1;
      border-radius: 10px;
      background-color: #FFF3D8;
      margin-left: 5px;
      color: #FFC547;
      display: flex;
      justify-content: center;
      align-items: center;

      .pi {
        font-size: 24px;
      }
    }
  }

  &__body {
    height: 101px;
  }
}
