label {
  &.active {
    font-size: 12px;
    top: -10px!important;
    left: 0 !important;
    color: #90AFC4 !important;
  }
}

.inputTextError {
  color: #c55469;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: -20px;
}