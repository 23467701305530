.branch-mobile {
  .item {
    &__wrapper {
      .row {
        .col {
          &:first-child {
            flex-basis: 200%;

            img {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

.appl-mobile {

}
