.mobile-menu {
  display: none;
}

@media (max-width: 1350px) {
}

@media (max-width: 820px) {

  .mobile-menu {
    display: flex !important;

    &__logo {
      position: relative;
    }

    &__hamb {
      cursor: pointer;
    }

    &__plate {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      z-index: 100;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
        }
      }
    }

    &__profile {
      background-color: #F7F7F7 !important;
      color: #373947 !important;
      padding: 20px;
    }

    &__client-name {
    }

    &__client-title {
      font-size: 15px;
    }

    &__exit {
      padding: 20px;
      cursor: pointer;
    }
    &__item {
      font-size: 20px;
      background-color: #FFF;
      color: #9C9C9C;

      a {
        display: block;
        padding: 20px;

      }

    }

    a {
      color: #9C9C9C;
    }
  }

}

@media (max-width: 479px) {
  .mobile-menu {
    &__hamb {
      padding-right: 20px;
    }
  }

}
