.notification {
  position: relative;
  top: -3px;
  padding-right: 15px;

  .pi {
    font-size: 1.2rem;
    color: rgba(48, 48, 48, 0.7);
  }

  .badge {
    position: absolute;
    right: 8px;
    top: -6px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    color: #4C4C4C;
    background-color: #E0E0E0;

    &.active {
      color: #fff;
      background-color: #EB5757;
    }
  }
}
