.pager-size {
  cursor: pointer;
  width: 200px;
  position: relative;

  i {
    margin-left: 8px;
    font-size: 10px;
  }
  &__title {
    text-align: right;
  }
  &__dropdown {
    position: absolute;
    right: 0;
    top: 25px;
    z-index: -1;
    background: #FFFFFF;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    width: 165px;

    height: 0;
    transition-property: opacity, height;
    transition-duration: .2s, 0s;
    transition-delay: 0s, .3s;
    opacity: 0;

    &.active {
      z-index: 1;
      height: auto;
      transition-delay: 0s, 0s;
      opacity: 1;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        text-align: left;
        list-style: none;
        padding: 8px 24px;
        color: #373947;

        &:hover {
          background-color: #F1F1F1;
        }
      }
    }
  }
}
