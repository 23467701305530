.error-container {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;

}

.error {
  text-align: center;
  padding: 0 30px;

  .p-button {
    display: block;
    margin: 0 auto;
  }
  &__title {
  }

  &__text {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  &__message {

  }
  &__button {

  }
}