.main-header {
  background-color: #FFF;
  border-bottom: 3px solid #008E9A;

  &__wrapper {
    max-width: 1650px;
    height: 65px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 146px;
    height: 37px;
  }

  .btn-login {

    .p-button {
      padding: 0.3rem 1.2rem;
    }
  }

  &__profile {
    color: #FFFFFF;
  }

  .main-menu {

    list-style: none;
    margin: 0;
    padding: 0 0 0 65px;
    flex-grow: 1;
    width: auto;

    &__item {
      border-bottom: 1px dashed transparent;
      display: inline;
      margin-right: 25px;

      a {
        color: #AFB0B5;

        &.active, &:hover {
          color: #4C4C4C;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .main-header {
    &__wrapper {
      max-width: 1240px;
      padding: 0 20px;
    }
  }
}

@media (max-width: 820px) {
  .main-header {
    .main-menu {
      display: none;
    }
  }
}

@media (max-width: 479px) {
  .main-header {
    &__wrapper {
      padding: 0;
      width: 100%;
    }

    &__profile {
      margin-right: 15px;
    }

    .main-menu {
      display: none;
    }

    &__logo {
      margin-left: 20px;
    }

    &__btn {
      margin-right: 15px;
    }
  }
}
