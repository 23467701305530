@import "src/custom/variables";


.page {
  &__booklet-list {
    .booklet-list {
      max-width: 1690px;
      margin: 0 auto;
      padding: 0 20px;

      &__table {
        margin: 30px 0 30px 0;
        display: flex;

        flex-wrap: wrap;

        .booklet-item {
          width: 340px;
          margin-right: 30px;
          margin-bottom: 40px;

          .image {
            display: flex;
            background-color: #F7F7F7;
            border-radius: 8px;

            height: 274px;
            width: 100%;
            justify-content: center;
            align-items: center;

            img {
              display: block;
            }
          }

          .header {
            font-size: 24px;
            margin: 25px 0 15px 0;
          }

          .content {
            margin-bottom: 25px;
            min-height: 130px;
            height: auto;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            border-width: 0;

            .p-button {
              padding: 0.4rem 1rem;
            }
          }
        }
      }
    }

    .title {
      padding: 40px 0 0 0;
      display: flex;
      align-items: center;

      &__sub {
        padding: 15px 0 20px;
        font-family: "Stolzl", Arial, Helvetica, sans-serif;
        color: #373947;
      }
    }

    .back-btn {
      padding: 0.4rem;
      line-height: 1rem;
      margin-right: 10px;
    }


  }
}


@media (max-width: 1600px) {

  .page__booklet-list {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;

    .title {
      max-width: 1240px;
      padding: 40px 0 0 0;
    }

  }
}

