@import './Review';

.author_official {
  &__underline {
    text-decoration: underline;
  }
}

.close {
  position: absolute;
  top: 0;
  right: -7px;

  i {
    cursor: pointer;

    &:hover {
      color: #EB5757;
    }
  }
}

.repost {
  .replay {

    &__form {
      //padding-right: 0;
    }

    &__btn_wrapper {
      position: absolute;
      right: -5px;
      bottom: -7px;
      padding: 12px 3px 3px 12px;
      background-color: #E6F4FF;
    }

    &__btn {
      &.p-button {
        background-color: #E6F4FF !important;
        border: 1px solid #9C9C9C !important;
        color: rgba(48, 48, 48, 0.7);;
        padding: 4px 12px !important;

        .p-button-label {
          font-size: 16px !important;
        }

        &:hover {
          background-color: #DBDBDB !important;
          border: 1px solid #DBDBDB !important;
          color: #FFFFFF;
        }
      }
    }

  }
}

.replay {
  background: #F5F5F5;
  border-radius: 8px;
  margin: 16px 0;

  &__author {
    position: relative;
    width: 100%;
  }

  &.official {
    background: #E6F4FF;
  }

  &__textarea_wrapper {
    height: auto;
    padding-bottom: 40px;

    textarea {
      //height: auto;
    }
  }

  &__btn-publish {
    display: flex;
    justify-content: flex-end;

    .publish {
      &.p-button {
        background-color: #E6F4FF !important;
        border: 1px solid #9C9C9C !important;
        color: rgba(48, 48, 48, 0.7);;
        padding: 4px 12px !important;

        .p-button-label {
          font-size: 16px !important;
        }

        &:hover {
          background-color: #DBDBDB !important;
          border: 1px solid #DBDBDB !important;
          color: #FFFFFF;
        }
      }

    }

  }

  &__text-wrapper {
    padding-right: 40px;
  }

  &__text {
  }

  &__list {
    min-height: 100px;
    position: relative;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    padding: 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__author {
    color: #9C9C9C;
    padding-bottom: 10px;
    display: flex;

    &_official {
      font-family: $font-family !important;
      color: #2F80ED !important;
    }

    span {
      color: #373947;
      padding-right: 20px;
    }

    &__date {
      display: flex;
    }
  }

  &__date {
    color: #9C9C9C;
    white-space: nowrap;
  }

  &__body {
  }

  &__form {
    width: 100%;

    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    padding-right: 70px;

    .close-ico {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 15px;
      height: 15px;
      cursor: pointer;

      &:hover {
        color: #EB5757;
      }
    }

    &.active {
      border-color: #008E9A;
    }

    textarea {
      font-size: 14px;
      font-family: $font-family;
      color: $color-text;
      width: 100%;
      resize: none;
      height: 80px;
      padding: 15px;
      display: block;
      border: none;
      overflow: auto;
      outline: none;
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    textarea::placeholder {
      font-family: $font-family;
    }
  }

  &__footer {
    padding-left: 15px;
    display: flex;
    height: 45px;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .author {
      font-family: $font-family2;
      color: #2F80ED;
      cursor: pointer;

      span {
        display: flex;
        align-items: center;

        img {
          display: block;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  &__btn_wrapper {
    position: absolute;
    right: -73px;
    bottom: -7px;
    padding: 12px 3px 3px 12px;
    background-color: #fff;
  }

  &__btn {
    &.p-button {
      box-sizing: border-box;
      border-radius: 2px;
      background-color: #DBDBDB !important;
      border: 1px solid #DBDBDB !important;
      color: #FFFFFF;
      padding: 4px 12px !important;

      .p-button-label {
        font-size: 26px !important;
      }

      &.active {
        background-color: #008E9A !important;
        border: 1px solid #008E9A !important;
        color: #FFFFFF;

        &:hover {
          background-color: #FFF !important;
          border: 1px solid #008E9A !important;
          color: #008E9A;
        }

      }

      &:hover {
        background-color: #DBDBDB !important;
        border: 1px solid #DBDBDB !important;
        color: #FFFFFF;
      }
    }
  }
}
