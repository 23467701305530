@import "src/custom/variables";

.page {
  &__integration-list {
    display: flex;
    flex-grow: 1;
    margin: 0 auto !important;

    .tooltip {
      &__wrapper {
        margin-left: 10px;
        justify-content: center;
        align-self: center;
        color: rgba(48, 48, 48, 0.7) !important;
      }
    }

    .title {
      padding: 40px 20px 20px 0;
      position: relative;
    }

    .p-button {
      &.excel {
        position: absolute;
        right: 0;
        bottom: 9px;
        background: transparent url("./../../../images/excel_ico.svg") 13px 50% no-repeat!important;
        padding-left: 48px;

        &:hover {
          background: #01B3C2 url("./../../../images/excel_ico2.svg") 13px 50% no-repeat!important;
        }
      }
    }

    .integration-list {
      max-width: 1690px;
      min-height: 32px;
      padding: 0 20px;
      margin: 0 auto !important;

      &__table {
        width: 100%;

        .name {
          &.disabled {
            span {
              color: #E0E2E8;

              img {
                opacity: 0.5;
              }
            }
          }

          a, span {
            display: flex;
            align-self: center;
            color: #FDAC4D;

            img {
              margin-right: 5px;
            }
          }
        }
      }

      &__wrapper {
        padding: 0 0 40px 0;

        h1 {
          span {
            color: #6C8DA3;
            font-weight: 450;
            display: inline-block;
            padding-left: 15px;
          }
        }
      }
    }
  }
}

.status-tag {
  color: #38A749;
  font-size: 14px;
  line-height: 17px;
  vertical-align: top;
  padding: 5px 13px;
  display: inline-block;
  border-radius: 0;
  background-color: #E7F8EE;

  &.own {
    color: #38A749;
  }

  &.yellow {
    color: #ffa400;
    background-color: #feffd9;
  }

  &.red {
    color: #EB5757;
    background-color: #FFECEC;
  }

  &.grey {
    color: #3e4f72;
    background-color: #dfe1e6;
  }
}

.integration-list-filter {
  padding: 12px 0 28px 0;
  font-size: 16px;
}

.p-datatable {
  .p-datatable-thead {
    tr {
      th {
        cursor: default !important;
        padding: 8px 5px;

        &.header-source {
          width: 20%;
        }

        &.header-title {
          width: 20%;

          span {
            font-weight: 400;
            color: #008E9A;
          }
        }

        &.header-status {
          width: 30%;

          div {
            display: flex;
          }
        }

        &.header-monitoring {
          width: 30%;

          div {
            display: flex;
          }
        }

        &.header-qrcode {
          width: 130px;

          div {
            display: flex;
          }

        }
      }

    }
  }

  .p-datatable-tbody {

    th, td, tr {
      padding: 8px 0 !important;
      line-height: 1rem;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }

    .branch-name {
      padding: 12px 0 !important;
      color: #6865FF;
    }

    .qr-btn {
      color: #008E9A;
      display: flex;
      justify-content: flex-end;
      img {
        position: relative;
        top: 2px;
        display: block;
      }

      span {
        margin-left: 8px;
      }
    }

  }
}

@media (max-width: 1600px) {

  .page__integration-list {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
  }
}




