@import "../../custom/variables";

.tooltip {
  display: flex;
  cursor: pointer;
  position: relative;

  .placeholder {
    display: block;
    z-index: 2;
    font-size: 12px;
    background-color: #fff;
    padding: 10px;
    line-height: 1rem;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    text-align: left;
    color: rgba(48, 48, 48, 0.7);
  }

  &:not(.mobile) {
    position: relative;

    &:hover {
      .placeholder {
        opacity: 1;
        bottom: 27px;
        left:50%;
        margin-left: -30px;
        top: auto;
        &.active {
          display: block;
        }

        &.position {
          &__right {
            left: auto;
            right: -22px;

            .triangle {
              right: 14px;
              left: auto;
            }
          }
        }

        .triangle {
          position: absolute;
          bottom: -10px;
          left: 31px;
          z-index: 4;
          margin-left: -17px;

          .triangle-with-shadow {
            width: 30px;
            height: 10px;
            position: relative;
            overflow: hidden;

            &:after {
              content: "";
              position: absolute;
              width: 19px;
              height: 19px;
              transform: rotate(45deg);
              top: -15px;
              left: 5px;
              box-shadow: -2px -2px 2px 4px rgba(0, 0, 0, 0.13);
            }

            &:before {
              content: "";
              position: absolute;
              width: 19px;
              height: 19px;
              transform: rotate(45deg);
              top: -15px;
              left: 5px;
              background-color: #fff;
            }
          }
        }
      }
    }

    .placeholder {
      position: absolute;
      left: -5000px;
      top: -2000px;
      opacity: 0;
      z-index: 2;
      min-width: 300px;
      width: auto;
      font-size: 12px;
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 5px 10px;
      line-height: 1rem;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
      border-radius: $border-radius;
      transition-property: opacity;
      transition-duration: .5s;

      &__inline {
        white-space: nowrap;
        min-width: 230px!important;
        width: auto!important;
      }
    }
  }

  &.mobile {
    display: flex;
    align-items: center;

    .placeholder {
      position: absolute;
      left: 0;
      top: -1270px;
      z-index: 5;
      width: 280px;

      &.active {
        top: 28px;
        left: -130px;

        .triangle {
          position: absolute;
          top: -10px;
          left: 50%;
          z-index: 4;
          margin-left: -17px;

          .triangle-with-shadow {
            width: 30px;
            height: 10px;
            position: relative;
            overflow: hidden;

            &:after {
              content: "";
              position: absolute;
              width: 19px;
              height: 19px;
              transform: rotate(45deg); /* Не забываем про префиксы */
              top: 5px;
              left: 5px;
              box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.13);
            }

            &:before {
              content: "";
              position: absolute;
              width: 19px;
              height: 19px;
              transform: rotate(45deg); /* Не забываем про префиксы */
              top: 5px;
              left: 5px;
              background-color: #fff;
            }

          }


        }

      }
    }
  }
}
