@import '../../../fonts/stylesheet.css';
@import "../../../custom/variables";
@import "../../App";


.OkMenu {
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  line-height: 1.7rem;
  color: #afb0b5 !important;
  background-color: transparent;
  border-width: 0;

  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 0 0 65px;
  flex-grow: 1;
  width: auto;

  &__item {

    padding-inline: 16px;
    list-style: none;
    position: relative;

    a, span {
      color: #afb0b5;
      cursor: pointer;

      &.selected {
        color: #4c4c4c;
      }

      &:hover {
        color: #4c4c4c;
      }
    }
  }

  .submenu {
    position: absolute;
    left: 0;
    top: 30px;
    max-height: 0;
    padding: 0;
    z-index: 10;
    transition: max-height 0.1s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    width: auto;

    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    background-color: #fff;

    &.active {
      transition: max-height 0.1s cubic-bezier(1, 0, 1, 0);
      overflow: hidden;
      max-height: 1000px;
    }

    &__wrapper {
    }

    &__item {
      white-space: nowrap;
      list-style: none;
      padding: 9px 34px 9px 24px;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
}