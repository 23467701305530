.footer {
  background-color: #fff;
  font-size: 16px;
  color: #373947;
  border-top: 3px solid #008E9A;

  a {
    color: #BDBDBD;
    text-decoration: none;

    &:hover {
      color: #BDBDBD;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1650px;
    min-height: 105px;
    margin: 0 auto;
    position: relative;
  }

  &__logo {
    height: 37px;
  }

  &__terms-of-use {
    flex-grow: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin-left: 40px;
    }

    li:first-child {
      margin-left: 75px;
    }


  }

  &__contact-us {
    a {
      color: #373947;
      line-height: 122%;
      display: block;
      &:hover {
        color: #373947;
      }

    }
  }
}
@media (max-width: 1600px) {
  .footer {
    &__wrapper {
      max-width: 1240px;
      padding: 0 20px;
      font-size: 14px;
    }
  }
}

@media (max-width: 1350px) {
  .footer {
    &__wrapper {
      padding: 0 20px;
    }

    &__terms-of-use {
      text-align: center;
      flex-direction: column;

      li {
        margin: 0 !important;
      }

      li:first-child {
        padding-top: 0;
      }

      li {
        padding-top: 10px;
      }
    }

  }
}

@media (max-width: 820px) {
  .footer {
    &__wrapper {
      width: auto;
      display: flex;
      flex-direction: column;
    }

    &__logo,
    &__contact-us {
      margin: 35px;
      text-align: center;
    }
  }
}

@media (max-width: 640px) {
  .footer {
    &__wrapper {
      width: auto;
      display: flex;
      flex-direction: column;
    }

    &__logo,
    &__contact-us {
      margin: 35px;
      text-align: center;
    }
  }
}

@media (max-width: 479px) {

}

