.source-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 8px 5px 0;
    font-size: 10px;
    display: flex;

    img {
      display: block;
    }

    &.type_status {
      border-radius: 30px;
      padding-left: 2px;
      &.error {
        border: 1px solid #FF8A00;
        color: #FF8A00;
      }

      &.published {
        border: 1px solid #008E9A;
        color: #008E9A;

      }

      span {
        margin: 0 10px 0 3px;
      }
    }
  }
}
