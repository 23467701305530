.filter-slider {

  &.disabled {
    .slide1{
      background-color: #999;
    }
    .nav {
      border-color: #ccc;
      background-color: #ccc;
    }
    li {
      cursor: default;
      border-color: #ccc;
      background-color: #ccc;
      a {
        color: #fff;
      }
    }
  }
  &__title {

  }

  .nav {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #008E9A;
    border-radius: 30px;
  }

  li {
    margin: 0 10px;
    cursor: pointer;

    &.active {
      a {
        transition-property: color;
        transition-delay: 0.2s;
        color: #fff;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 7px 10px;
      z-index: 2;
      font-size: 16px;
      color: #008E9A;

    }
  }

  .slide1 {
    border-radius: 30px;
    background-color: #008E9A;
    position: absolute;
    display: block;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1.05);
    bottom: 0;
    height: 100%;
  }
}
