.page {
  &__dashboard {
    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .empty_data {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      padding-bottom: 10px;
    }

    .dashboard {
      max-width: 1690px;
      min-height: 32px;
      padding: 0 20px;
      margin: 0 auto !important;

      .color_red {
        color: #EB5757;
      }

      .title {
        padding: 40px 0 12px;
        position: relative;

        .dashboard-filter {
          position: absolute;
          right: 0;
          top: 24px;

          .download {
            &__wrapper {
              margin-left: 10px;
            }
          }
        }

      }

      h2 {
        color: #373947;
      }

      .content {
        &__block {
          padding-bottom: 10px;

          .ico {
            padding-right: 5px;
            position: relative;
            top: 3px;

            img {
              width: 17px;
              height: 17px;
            }

            &__kubok, &__hand_down {
              padding-right: 12px;
            }
          }

          .row {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            &__md_0 {
              margin-bottom: 0;
            }

            &__md_5 {
              margin-bottom: 5px !important;
            }

            &__md_10 {
              margin-bottom: 10px;
            }

            .col {
              color: #373947;

              &__percent {
                width: 12% !important;
              }

              &__w10 {
                width: 10%;
                white-space: nowrap;

                &_min-width {
                  min-width: 10px;
                }
              }

              &__w20 {
                width: 20%;

                &_min-width {
                  min-width: 30px;
                }
              }

              &__w25 {
                width: 25%;

                &_min-width {
                  min-width: 273px;
                }
              }

              &__w40 {
                width: 40%;

                &_min-width {
                  min-width: 100px;
                }
              }

              &__w50 {
                width: 50%;

                &_min-width {
                  min-width: 560px;
                }
              }

              &__w70 {
                width: 70%;

                &_min-width {
                  min-width: 100px;
                }
              }

              &__w68 {
                width: 68%;

                &_min-width {
                  min-width: 100px;
                }
              }

              &__ovh {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-right: 10px;
              }
            }
          }

          .requests {
            fieldset {
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                margin: 0 6px;
                position: relative;

                &.value {
                  font-size: 30px;
                  top: -3px;
                }

                &.sub_value {
                  font-size: 16px;
                  color: #6FCF97;
                  top: 2px;
                }
              }

              .right_value {
                display: flex;
                flex-direction: column;
                position: relative;
                top: -4px;
                margin-left: 5px;

                &__positive {
                  img {
                    margin-right: 6px;
                    position: relative;
                    top: 3px;
                  }

                  i {
                    font-style: normal;
                    color: #6FCF97;
                  }
                }

                &__negative {
                  img {
                    margin-right: 6px;
                    position: relative;
                    top: 3px;

                  }

                  i {
                    font-style: normal;
                    color: #EB5757;
                  }
                }
              }
            }
          }

          fieldset {
            margin: 0 8px;
            min-width: 0 !important;

            &.ml_0 {
              margin-left: 0;
            }

            &.mr_0 {
              margin-right: 0;
            }

            &:hover {
              border-color: #008E9A;

              legend {
                color: #008E9A;
              }
            }
          }

          &.statuses-messages {
            .branch {
              position: relative;

              .double-block {
                display: flex;
                justify-content: space-around;
                color: #373947;
                padding: 6px 0;
                position: relative;

                &__filter {

                  width: 30px;
                  height: 16px;
                  position: absolute;
                  content: '';
                  right: 12px;
                  top: 10px;
                  cursor: pointer;
                  background: transparent url('./../../../images/dashboard/ico_spread.png') left 8px no-repeat;
                  background-size: 22px;

                  ul {
                    position: absolute;
                    right: 0;
                    top: 0;
                    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
                    border-radius: 4px;
                    background-color: #fff;
                    padding: 0;
                    margin: 0;
                    width: 185px;
                    z-index: 1;

                    li {
                      list-style: none;
                      cursor: pointer;
                      padding: 8px 24px;
                      color: #373947;
                      position: relative;

                      .pi {
                        position: absolute;
                        font-size: 10px;
                        left: 8px;
                        top: 15px;
                      }

                      &:hover {
                        background-color: #F1F1F1;
                      }
                    }
                  }
                }

                &:after {
                  position: absolute;
                  left: 50%;
                  top: -4px;
                  height: 40px;
                  border-right: 1px solid #9C9C9C;
                  content: '';
                }

                .name {
                  position: relative;
                  top: -5px;
                  font-size: 16px;
                  margin-right: 20px;
                }

                .value {
                  font-size: 30px;

                  i {
                    font-size: 12px;
                  }
                }

                .ico_arrow {
                  padding-left: 5px;
                }

                .left, .right {
                  width: 50%;
                  text-align: center;
                }


              }

              fieldset {
                display: flex;
                justify-content: center;
                align-items: center;

                .col {
                  padding-top: 10px;
                  margin: 0 8px;
                  white-space: nowrap;

                  .ico {
                    top: 3px;
                  }

                  &__big-text {
                    font-size: 18px;
                  }

                  &__big {
                    padding: 0;
                    display: flex;
                    align-items: center;

                    img {
                      display: block;
                      width: 40px;
                      height: 40px;
                    }

                    .value {
                      font-size: 30px;
                      margin-left: 7px;
                      margin-right: 30px;
                    }
                  }
                }
              }
            }

            .integration {
              fieldset {
                display: flex;
                justify-content: center;
                align-items: center;

                .col {
                  &__big {
                    display: flex;
                    align-items: center;

                    img {
                      display: block;
                      width: 40px;
                      height: 40px;
                    }

                    .value {
                      font-size: 30px;
                      margin-left: 7px;
                      margin-right: 30px;
                    }
                  }

                  &.text {
                    position: relative;
                    top: 8px;
                    line-height: 14px;
                    margin-left: 0;
                  }
                }

                .notification {
                  top: 15px;
                }
              }
            }

            .messages {
              margin-left: 8px;
              padding-top: 8px;
              padding-bottom: 2px;
              height: 100%;

              &__text {
                background-color: rgba(#EB5757, 0.13);
                box-shadow: -1px 4px 5px -3px rgba(0, 0, 0, 0.13);
                border-radius: 3px;
                border-left-style: solid;
                border-left-width: 4px;
                border-left-color: #EB5757;
                height: 100%;

                .text {
                  padding: 0 25px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.monitoring {
  &__charts {
    display: flex;
    width: 100%;
  }

  .chart__header .header__right-side {
    font-size: 14px;
    line-height: 17px;
    color: #373947;
  }

  .row {
    &:first-child {
      .col {
        fieldset {
          min-height: 210px;
        }
      }
    }
  }

  .double-block {
    display: flex;
    justify-content: space-around;
    color: #373947;
    padding: 6px 0;
    position: relative;

    &__filter {

      width: 30px;
      height: 16px;
      position: absolute;
      content: '';
      right: -8px;
      top: -18px;
      cursor: pointer;
      background: transparent url('./../../../images/dashboard/ico_spread.png') left 8px no-repeat;
      background-size: 22px;

      ul {
        position: absolute;
        right: 0;
        top: 0;
        box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        background-color: #fff;
        padding: 0;
        margin: 0;
        width: 185px;
        z-index: 1;

        li {
          list-style: none;
          cursor: pointer;
          padding: 8px 24px;
          color: #373947;
          position: relative;

          .pi {
            position: absolute;
            font-size: 10px;
            left: 8px;
            top: 15px;
          }

          &:hover {
            background-color: #F1F1F1;
          }
        }
      }
    }

    &:after {
      position: absolute;
      left: 50%;
      top: -4px;
      height: 40px;
      border-right: 1px solid #9C9C9C;
      content: '';
    }

    .name {
      position: relative;
      top: -5px;
      font-size: 16px;
      margin-right: 20px;
    }

    .value {
      font-size: 30px;

      i {
        font-size: 12px;
      }
    }

    .ico_arrow {
      padding-left: 5px;
    }

    .left, .right {
      width: 50%;
      text-align: center;
    }


  }
}

fieldset {
  legend {
    font-size: 14px;
    line-height: 16px;
    color: #373947;
  }

  height: 100%;
  border: 1px solid #c1c0c0;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 13px;
}

@media (max-width: 1600px) {
  .page {
    &__dashboard {
      .dashboard {
        max-width: 1240px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  .page {
    &__dashboard {
    }

    .dashboard {

      .empty_data {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        padding-bottom: 10px;
        font-size: 10px;
      }

      .title {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 25px;

          span {
            display: flex;
            font-size: 14px;
          }
        }

        .dashboard-filter {
          position: static;

          .filter__item {
            .filter-slider {
              li {
                a {
                  padding: 3px 10px;
                  font-size: 12px;
                }
              }
            }
          }
        }

        .dashboard-download-pdf {

        }
      }

      .content {
        margin-bottom: 50px;

        &__block {
          h2 {
            font-size: 14px;
            margin-bottom: 10px;
          }

          a {
            width: 100%;
          }

          fieldset {
            margin: 2px;

            legend {
              font-size: 8px;
            }
          }

          &.statuses-messages, .monitoring {

            &__charts {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              overflow: scroll;
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 15px;
            }

            .row {
              width: 100%;
              display: flex;
              justify-content: space-around;

              .right_side {
                display: flex;
                flex-direction: column;

                &__row {
                  display: flex;
                  flex-direction: row !important;
                  padding-top: 7px;
                }
              }

              &__md_10 {
                position: relative;
                top: -15px;
                margin-bottom: -15px;
              }

              .col {
                font-size: 10px;
                line-height: 16px;
                display: flex;
                flex-direction: row;
                padding: 0 !important;
                margin: 0 !important;

                .text {
                  font-size: 7px;
                  line-height: 8px !important;
                  position: static !important;
                }

                .notification {
                  top: 0;
                  padding: 0;
                  margin-right: 5px;

                  .badge {
                    width: 10px;
                    height: 10px;
                    font-size: 9px;
                  }

                  .pi {
                    font-size: 13px;
                  }
                }

                .ico {
                  margin-right: 5px;
                  padding: 0;

                  img {
                    width: 12px;
                    height: 12px;
                    display: block;
                  }

                }

                &__big {
                  .value {
                    font-size: 14px !important;
                    margin-right: 0 !important;
                  }

                  span {
                    img {
                      display: block;
                      width: 27px !important;
                      height: 27px !important;
                    }
                  }
                }

                .messages {
                  margin: 0;

                  .text {
                    font-size: 10px;
                    line-height: 16px !important;
                    padding: 12px;
                  }
                }
              }
            }
          }

          &.monitoring {
            .row {
              justify-content: normal;
            }

            .col {
              width: 100%;
            }

            .double-block {
              top: -5px;

              &:after {
                top: 2px;
                height: 29px;
              }

              .name {
                font-size: 10px;
                position: static;
                margin-right: 5px;
              }

              .value {
                font-size: 14px;
              }

              .ico_arrow {
                img {
                  height: 14px;
                }
              }

              .left {
              }

              .right {
              }
            }
          }

          .requests {
            margin-bottom: 0;

            fieldset {
              span {
                position: static;
              }
            }

            &.row {
              .col {
                .row {
                  &__md_0 {
                    position: relative;
                    top: -4px;
                  }
                }

                .value {
                  font-size: 14px !important;
                  margin-right: 0 !important;
                }

                .sub_value {
                  font-size: 10px;
                }

                .right_value {
                  .right_value {
                    &__positive, &__negative {
                      display: flex;
                      font-size: 8px;
                      line-height: 16px;

                      img {

                        display: block;
                        width: 10px !important;
                        height: 10px !important;
                      }

                      i {

                      }
                    }

                    &__negative {
                    }
                  }
                }

                span {
                  img {
                    display: block;
                    width: 27px !important;
                    height: 27px !important;
                  }
                }

              }
            }
          }

          .rating {
            margin-bottom: 0;

            fieldset {
              span {
                position: static;
              }
            }

            &.row {
              .col {
                line-height: 16px;
                width: 100%;

                &__w100 {
                  width: 100%;
                }

                &__w10 {
                  margin-right: 5px;
                }

                .row {
                  display: block;
                  clear: both;
                  width: 100%;

                  .col {
                    font-size: 10px;
                    margin-right: 3px;
                    width: 32px;
                    float: left;

                    &__ovh {
                      width: calc(100% - 125px);
                      overflow: hidden;
                    }

                    &__percent {
                      width: 45px !important;
                    }

                    .ico {
                      position: relative;
                      top: 1px;
                      padding-right: 3px;

                      img {
                        width: 10px;
                        height: 10px;
                      }
                    }

                  }

                  &__md_0 {
                    position: relative;
                    top: -4px;
                  }
                }
              }
            }
          }

        }
      }
    }
  }
}

