.filter-button {
  position: relative;

  &__btn-clear {
    margin-top: 2px;
    margin-left: 5px;
    border-radius: 10px;
    color: #FFFFFF;
    width: 21px;
    min-width: 21px;
    height: 21px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      font-size: 14px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    cursor: pointer;
    color: #303030;
    padding: 7px 22px;
    background: #FFFFFF;
    border: 1px solid #373947;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 16px;
    &:hover, &.selected {
      color: #15AAB7;
      border-color: #15AAB7;
    }

    &.active {
      color: #FFFFFF;
      background-color: #008E9A;
      border-color: #15AAB7;
    }

    &.fixed-width {
      width: 200px;

      &__mobile {
        width: 170px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    top: 42px;
    background: #FFFFFF;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    z-index: 1;
    overflow-x: hidden;

    height: 0;
    transition-property: opacity, height;
    transition-duration: .2s, 0s;
    transition-delay: 0s, .2s;
    opacity: 0;

    &.active {
      height: auto;
      transition-delay: 0s, 0s;
      opacity: 1;
    }

    .search-field {
      position: relative;

      &__cross {
        position: absolute;
        right: 10px;
        top: 14px;
        cursor: pointer;
      }

      &__input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #C1C0C0;
        font-size: 16px;
        color: #373947;
        padding: 8px 24px;

        &:focus {
          outline: none;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      min-width: 230px;
      max-height: 250px;
      overflow-y: auto;

      li {
        list-style: none;
        cursor: pointer;
        padding: 8px 24px;
        color: #373947;
        position: relative;

        .pi {
          position: absolute;
          font-size: 10px;
          left: 8px;
          top: 15px;
        }

        &.select {
          background-color: #F1F1F1;
        }

        &:hover {
          background-color: #F1F1F1;
        }
      }
    }
  }

  &__dropdown-loading {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.7;
  }
}

.warning {

  .filter-button {
    &__title {
      white-space: nowrap;
      color: #EB5757;
      border-color: #EB5757;

      &:hover, &.selected {
        color: #EB5757;
        border-color: #EB5757;
        background-color: #FFF;
      }

      &.active {
        color: #FFF;
        border-color: #EB5757;
        background-color: #EB5757;
        .badge {
          background-color: #fff;
          color: #EB5757;
        }
      }
    }


  }
  &.disabled {

    &:hover {
      .filter-button {
        &__title {
          cursor: default;
          border-color: #ccc;
          color: #fff;
          background-color: #ccc;
        }
      }

    }
  }

  .badge {
    display: flex;
    min-width: 20px;
    height: 20px;
    border:1px solid #EB5757;
    border-radius: 20px;
    color: #fff;
    background-color: #EB5757;
    justify-content: center;
    align-items: center;
    padding: 3px;
    margin-left:5px;
    align-self: center;
    font-size: 15px;
  }

}

.disabled {
  .filter-button {
    &__title {
      cursor: default;
      border-color: #ccc;
      color: #fff;
      background-color: #ccc;

      &:hover, &.selected, &.active {
        color: #fff !important;
        border-color: #ccc !important;
      }

      &.active {
        color: #fff !important;
        border-color: #ccc !important;
      }

    }
  }
}
