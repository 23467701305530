.dialogError {
  width: 475px;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;

  .p-dialog-header {
    display: none !important;
  }

  .p-dialog-content {
    border: 0 !important;
    background-color: transparent !important;
  }
}

.blockError {
  max-width: 475px;
  margin: 0 auto 105px;
  text-align: left !important;
  padding: 10px 10px;

  .wrapper {
    border-radius: 2px;
    padding-left: 70px;
    display: flex;

    .close {
      padding: 15px;
      cursor: pointer;
    }
  }

  .text {
    padding: 15px 15px 15px 0;
    font-size: 14px;
    line-height: normal;

    a {
      color: #008E9A;
    }
  }

  .error {
    color: #C55469;
    background: url("./../../images/info_error_ico.svg") #FFD6D9 22px 16px no-repeat;

    .close {
      background-color: #FFD6D9;
    }
  }
}

@media (max-width: 320px) {
  .page {
    .dialogError {
      margin: 0 10px 235px;
    }
  }
}